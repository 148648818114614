<script>
/**
 * @Author: Andrea Dittler | webspezi.com
 * @Date:   2023-04-03 19:58:45
 * @Last Modified by:   Andrea Dittler | webspezi.com
 * @Last Modified time: 2025-02-17 15:23:39
 */

</script>

<template>
  <div>
    <h1 class="special-heading light-special mb-5">Gehen Sie nicht gleich ins Wasser!</h1>
    <div role="" class="row justify-content-center align-items-start  wsFlexTyp-standard" id="Kombiblk_block1__6">
      <div class=" col-12 col-sm-6 col-lg-7 ">
        <div class="text-white  text-center">
          <p>Wenn ich Ihnen direkt weiterhelfen soll, laden Sie sich den Teamviewer-Quicksupport herunter und nennen mir
            am Telefon ID und Kennwort.</p>
          <p>Telefon: +49-4152-919333</p>
        </div>


      </div>
    </div>
    <div role="" class="row justify-content-center align-items-start  wsFlexTyp-standard">
      <div class="wsFlex col-12 col-sm-8 col-md-6 col-lg-4 has-wsBlocksInfoBox KombiEbene1 ">

        <div class="milchglas position-relative" id="milchglas" ref="milchglas">
          <div class="innen" style="cursor: pointer;">
            <div class="iconbox iconbox-light iconbox-center zentriert">
              <div class="iconbox-wrapper">
                <div class="iconbox-icon"><i :class="`${os.icon} fa-6x fab text-white`"></i></div>
                <div class="iconbox-content">
                  <h3 class="mb-5">{{ os.name }}</h3>
                  <p></p>
                  <p>Das Fernwartungstool Teamviewer Quicksupport</p>
                  <p><strong>für {{ os.name }} </strong></p>
                  <p><a href="https://custom.teamviewer.com/6iexzzn" class=" stretched-link" target="_blank">finden Sie
                      hier</a></p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>





    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, onBeforeUnmount, computed, onMounted, onUpdated, watch } from 'vue'
import { applyMilchglasEffect } from './shared.js'
const os = computed(() => {
  const userAgent = window.navigator.userAgent;
  let os = null;

  if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
    os = { name: 'MacOS', icon: 'fa-apple', link: 'https://get.teamviewer.com/webspezi' };
  } else if (/Windows|Win16|Win32|Win64|WinCE/.test(userAgent)) {
    os = { name: 'Windows', icon: 'fa-windows', link: 'https://get.teamviewer.com/webspezi' };
  } else if (/Linux/.test(userAgent)) {
    os = { name: 'Linux', icon: 'fa-linux', link: 'https://get.teamviewer.com/webspezi' };
  } else if (/iPhone|iPod|iPad/.test(userAgent)) {
    os = { name: 'iOS', icon: 'fa-apple', link: 'https://get.teamviewer.com/webspezi' };
  } else if (/Android/.test(userAgent)) {
    os = { name: 'Android', icon: 'fa-android', link: 'https://play.google.com/store/apps/details?id=com.teamviewer.quicksupport.market' };
  } else {
    os = { name: 'Unbekannt', icon: 'fa-question', link: 'https://get.teamviewer.com/webspezi' };
  }

  return os;
})
onBeforeMount(() => {
  window.addEventListener('resize', applyMilchglasEffect);
  window.addEventListener('scroll', applyMilchglasEffect);
  //applyMilchglasEffect();
});
onUpdated(() => {
  applyMilchglasEffect();
});
onMounted(() => {
  applyMilchglasEffect();
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', applyMilchglasEffect);
  window.removeEventListener('scroll', applyMilchglasEffect);
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
div {
  font-size: clamp(16px, 1.3vw, 25px);
  font-weight: 400;
}
</style>
